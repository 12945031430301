import { useLocation, Link } from "react-router-dom"
import { useState } from "react";
import { allowedSearch, searchRule } from "../funcs"
import { Chara, ExpandedTree } from "../types/Dict";
import { SearchBox } from "./fixed";
import { CharaLink } from "./top";

export const SearchPage = (props: any) => {
    const dictData: Chara[] = props.dict

    const search = useLocation().search
    const query = new URLSearchParams(search)

    const tag = query.get('tag')
    const value = decodeURIComponent(query.get('value')!)
    const rule = query.get('rule')

    let filtered: any[] = []
    if (tag && allowedSearch.includes(tag) && Object.keys(searchRule).includes(rule!)) {
        filtered = filterDict(dictData, tag, value!, rule!)
    } else { //不正な入力が行われた場合
        filtered = []
    }

    return (
        <div>
            <h1>検索結果</h1>
            <SearchBox tag={tag} search={value} rule={rule}/>
            <p>
                <b>{filtered.length}</b>件の雰字が見つかりました。
            </p>

            <div className='charaList'>
                {filtered.map(v => 
                    <CharaLink id={v.data.id} chara={v.data.chara} desc={v.desc} key={v.data.id}/>
                )}
            </div>
        </div>
    )
}

const filterDict = (dict: Chara[], tag: string, value: string, rule: string) => {
    const [lang, prop] = tag.split("-")
    return dict.map(e => {
        let judge = false

        try {
            if (tag == "qo-parts") {
                judge = deepTreeSearch(e.chara, value.split(""), dict) //構成要素と検索を一文字づつに切り分けたものを比較
                return {data: e, judge, desc: e.chara}
            }

            let sample = ""
            if (lang == "qo") { //雰字 (転写など、メタ情報なので最上位にある)
                sample = e[prop]
            } else {
                sample = e.langs[lang][prop]
            }

            judge = matchRule(rule, String(sample), value)
            return {data: e, judge, desc: sample}
        } catch (error) {
            judge = false
            return {data: e, judge, desc: ""}
        }
    }).filter(c => c.judge)
}

const matchRule = (rule: string, text: string, index: string) => {
    switch (rule) {
        case "part":
            return text.includes(index)
        case "start":
            return text.startsWith(index)
        case "end":
            return text.endsWith(index)
        case "perfect":
            return text == index
        case "reg":
            return text.match(index) !== null
        default:
            return false
    }
}
const deepTreeSearch = (judgeChara: string, values: string[], dict: Chara[]) => {
    const expanded = [treeExpand(judgeChara, dict)]
    return values.every(v => treeSearch(expanded, v))
}

const treeExpand: (chara: string, dict: Chara[]) => ExpandedTree
= (chara: string, dict: Chara[]) => {
    const matchChara = dict.find(c => c.chara == chara)

    if (!matchChara) { return {} }
    if (matchChara.structure.type == "radical") { //部首ならば下位の部品はない
        return { part: chara }
    }

    const subs = matchChara.parts
        .filter(p => !p.special) //特殊部品は無限再帰を起こすため除去
        .map(p => treeExpand(p.char, dict))

    return { part: chara, subs: subs }
}

const treeSearch: (Tree: ExpandedTree[], value: string) => boolean
= (Tree: ExpandedTree[], value: string) => {
    return Tree.some((t, idx) => {
        if (t.part == value) {
            Tree.splice(idx, 1)
            return true //存在するならそれを削除して探索終了
        } else if (!t.subs) { //末端ならば探索終了
            return false
        } else {
            return treeSearch(t.subs, value)
        }
    })
}