import { useState } from "react"
import { Link } from "react-router-dom"
import { allowedSearch, langNames, propNames, searchRule, noRule } from "../funcs"

export const Header = () => {
    const [search, setSearch] = useState("")

    return (
        <div className="header">
            <div className="headerBox">
                <div className="logoBox">
                    <Link to="/" className="headLogo">
                        <img src="/toplogo2.png" className="headLogoImg"/>
                    </Link>
                </div>
                <Indexs/>
                <HeaderSearchBox search={[search, setSearch]}/>
            </div>
        </div>
    )
}

export const TopBox = () => {
    const tag = "qo-chara"
    const search = ""
    const rule = "part"

    return (
        <div className="topBox">
            <div className="topLogoBox">
                <img src="/toplogo2.png" className="topLogo"/>
            </div>
            <span className="topTitle">Web雰字典 <span className="phun">栄字万録</span></span>
            <SearchBox tag={tag} search={search} rule={rule}/>
            <Indexs/>
        </div>
    )
}

export const Footer = () => {
    return (
        <div className='footer'>
            © <a href='https://kaeru2193.net'>かえる</a> 2023-2024
            <div className="credit">ロゴ字: <a href="https://x.com/Esruudya">蘆名院</a>様</div>
        </div>
    )
}

export const Indexs = () => <div className="indexLinkBox">
    <div className="indexLink">
        <Link to="/index/strokes">総画索引</Link>
    </div>
    <div className="indexLink">
        <Link to="/index/phun">雰音索引</Link>
    </div>
</div>

export const SearchBox = (props: any) => {
    const [tag, setTag] = useState(props.tag)
    const [search, setSearch] = useState(props.search)
    const [rule, setRule] = useState(props.rule)

    const searchProcess = () => {
        window.location.href = `/search/?tag=${tag}&value=${encodeURIComponent(search)}&rule=${rule}`
    }

    return (
        <div className="searchBox">
            <input className="textInput"
                type="text"
                defaultValue={search}
                onChange={(e) => {setSearch(e.target.value)}}
                onKeyDown={(e) => {if (e.key == "Enter") { searchProcess() }}}
                placeholder="書尋事在之、、"
            />
            <div className="selectContainer">
                <select className="tagSelect"
                    defaultValue={tag}
                    onChange={(e) => setTag(e.target.value)}
                >
                    {allowedSearch.map((t, idx) => {
                        const [lang, prop] = t.split("-")
                        const optionName = `${propNames[prop]} (${langNames[lang]})`
                        return <option key={idx} value={t}>{optionName}</option>
                    })}
                </select>
            </div>
            {!noRule.includes(tag) && 
            <div className="selectContainer">
                <select className="ruleSelect"
                    defaultValue={rule}
                    onChange={(e) => setRule(e.target.value)}
                >
                    {Object.keys(searchRule).map((r, idx) => 
                        <option key={idx} value={r}>{searchRule[r]}</option>
                    )}
                </select>
            </div>}
            <button className="searchButton"
                onClick={() => searchProcess()}
            ><span className="phun">尋</span></button>
        </div>
    )
}

const HeaderSearchBox = (props: any) => {
    const [search, setSearch] = props.search

    const searchProcess = () => {
        window.location.href = `/search/?tag=qo-chara&value=${encodeURIComponent(search)}&rule=part`
    }

    return (
        <div className="headerSearchBox">
            <input className="textInput"
                type="text"
                defaultValue={search}
                onChange={(e) => {setSearch(e.target.value)}}
                onKeyDown={(e) => {if (e.key == "Enter") { searchProcess() }}}
                placeholder="書尋字在之、、"
            />
            <button className="searchButton"
                onClick={() => searchProcess()}
            ><span className="phun">尋</span></button>
        </div>
    )
}