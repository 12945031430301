import { Link } from 'react-router-dom';

export const NotFound = () => {
    return (
        <>
            <h2>404 Page Not Found</h2>
            <span className="phun404 phun">〤〇〤 其雷紙不居在之蔓処。</span>
            <p>お探しのページは見つかりませんでした。</p>
            <Link to="/">トップページに戻る</Link>
        </>
    )
}