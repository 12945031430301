import { TopBox } from './fixed';
import { Link } from 'react-router-dom';

export const TopPage = (props: any) => {
    const dictData: any[] = props.dict

    return (
        <div className='topWrapper'>
            <TopBox/>
            <p>
                人工言語「
                <a href="https://kaeru2193.net/phunlang" target="_blank">雰語</a>
                」で用いられる表語文字体系「雰字」のオンライン字典です。現在<b>{dictData.length}</b>文字が収録されています。
            </p>
            <h2>雰字一覧</h2>

            <div className='charaList'>
                {dictData.map(v =>
                    <CharaLink id={v.id} chara={v.chara} desc={v.chara} key={v.id}/>
                )}
            </div>
        </div>
    )
}

export const CharaLink = (props: {id: number, chara: string, desc: string}) => <Link to={`/chara/${props.id}`}>
    <div className='charaLink'>
        <span className='chara phun'>{props.chara}</span>
        <span className='charaPron serif'>{props.desc}</span>
    </div>
</Link>