export async function FetchDictData(url: string) {
    try {
        const res = await fetch(url);

        if (!res.ok) {
            throw new Error("データの取得に失敗しました");
        }

        const data = await res.json();
        return data
    } catch (error) {
        console.error(error);
        throw error
    }
}

export const langNames: any = {
    "qo": "雰字",
    "pn": "雰語",
    "mpn": "中古雰語",
    "tp": "トキポナ転写"
}

export const specialDesc: any = {
    "国": "囲みの象形",
    "家": "屋根の象形",
    "三": "重複の省略",
    "一": "横画",
    "零": "点"
}

export const propNames: any = {
    "chara": "漢字翻字",
    "id": "PhunID",
    "pron": "読み",
    "strokes": "画数",
    "parts": "構成"
}

export const allowedSearch: string[] = [
    "qo-chara",
    "qo-id",
    "qo-strokes",
    "qo-parts",
    "pn-pron",
    "mpn-pron",
    "tp-pron",
]

export const searchRule: any = {
    "part": "部分一致",
    "start": "前方一致",
    "end": "後方一致",
    "perfect": "完全一致",
    "reg": "正規表現"
}

export const noRule: any = [
    "qo-parts"
]

export const weqoRank = [
    ["k", "g", "q", "j", "t", "d", "c", "p", "b", "x", "s", "z", "f", "v", "n", "m", "l", "h", "y", "w", ""], //頭子音
    ["a", "e", "o", "i", "u", "ao", "ai", "ua", "uo"], //母音
    ["", "s", "ng", "n", "m", "l"], //末子音
    ["1", "2", "3"] //声調
]

export const fontName: any = {
    pwrite: "PhunWrite",
    psans: "PhunSans",
    pdot: "PhunDot"
}