import { useParams } from "react-router-dom";
import { NotFound } from './404';
import { langNames, specialDesc, fontName } from "../funcs";
import { Link } from 'react-router-dom';
import { Chara } from "../types/Dict";

export const CharaPage = (props: any) => {
    const params = useParams();
    const charaID = Number(params.id)

    const dictData: Chara[] = props.dict
    const matchData: any = dictData.find(v => v.id == charaID)

    if (matchData !== undefined) {
        const prev = <PrevButton dict={dictData} id={charaID}/>
        const next = <NextButton dict={dictData} id={charaID}/>

        return (
            <div className='pageWrapper'>
                <CharaInfo data={matchData} prev={prev} next={next}/>
                <CharaLangs data={matchData} dict={dictData}/>
            </div>
        )
    } else {
        return <NotFound />
    }
}

const CharaInfo = (props: any) => {
    const data: any = props.data

    return (
        <div className='info'>
            <div className="moveLink">
                {props.prev}
                {props.next}
            </div>
            <div className='charaFrame'>
                <span className='mainChara phun'>{data?.chara}</span>
            </div>
            <div><span className='kanji serif'>{data?.chara}</span></div>
            <table className='dataTable'>
                <tbody>
                    <tr>
                        <th>PhunID</th>
                        <td>{data.id}</td>
                    </tr>
                    <tr>
                        <th>画数</th>
                        <td>{data.strokes}</td>
                    </tr>
                    <tr>
                        <th>部首</th>
                        <td>{data.radical}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const CharaLangs = (props: {data: Chara, dict: Chara[]}) => {
    const data = props.data.langs
    return (
        <div className='langs'>
            <div className='langData'>
                <h2>字構成</h2>
                <div className='charaStructure'>
                    <CharaStructure char={props.data.chara} dict={props.dict} depth={1}/>
                </div>
            </div>
            
            {Object.keys(data).map((v, idx) => {
                const setLink = (links: any[]) => {
                    if (links) {
                        return (
                        <div className="langLinks">
                            {links.map((l: any, idx: number) =>
                                <span><a href={l.url} key={idx}>{l.name}</a></span>
                            )}
                        </div>
                        )
                    }
                }
                
                return (
                <div className='langData' key={"l" + idx}>
                    <h2>{langNames[v]}</h2>
                    <div>
                        <div className="dataRow">
                            <div className="dataLabel">読み</div>
                            <div className="data">{data[v].pron}</div>
                        </div>
                        <div className="dataRow">
                            <div className="dataLabel">字義</div>
                            <div className="data">{data[v].mean}</div>
                        </div>
                    </div>
                    {setLink(data[v].links)}
                </div>)
            })}

            {props.data.support[0] && <div className='langData'>
                <h2>字形</h2>
                <div className="charaFontBox">
                    {props.data.support.map(s => <div className="charaFont" key={s}>
                        <span className={`fontName ${s}`}>{props.data.chara}</span>
                        <span className="fontNameLabel">{fontName[s]}</span>
                    </div>)}
                </div>
            </div>}
        </div>
    )
}

const PrevButton = (props: any) => {
    const data = props.dict.find((e: any) => e.id == props.id - 1)
    return data
        ? <span className="prevButton"><Link to={`/chara/${data.id}`}>{`< `}<span className="phun">{data.chara}</span></Link></span>
        : <span className="prevButton disabled"><span>{`< -`}</span></span>
}

const NextButton = (props: any) => {
    const data = props.dict.find((e: any) => e.id == props.id + 1)
    return data
        ? <div className="nextButton"><Link to={`/chara/${data.id}`}><span className="phun">{data.chara}</span>{` >`}</Link></div>
        : <div className="nextButton disabled"><span>{`- >`}</span></div>
}

const CharaStructure = (props: {char: string, dict: Chara[], modified?: boolean, depth: number}) => {
    if (props.depth > 10) {return <div className="treeNode">Error: 無限再帰</div>}

    const charData = props.dict.find(c => c.chara == props.char)
    if (!charData) {
        return <div className="treeNode">?</div>
    }
    if (charData.structure.type == "radical") {
        return <div className={`treeNode ${props.modified ?"modified" :""}`}>
            <NodeChara char={charData.chara} id={charData.id}/>
        </div>
    }

    const nodes = charData.parts.map(r => {
        if (r.special) {
            return <div className="treeNode specialDesc serif">({specialDesc[r.char]})</div>
        }
        return <CharaStructure char={r.char} dict={props.dict} depth={props.depth + 1} modified={r.modify ?true :false}/>
    })

    return <div className={`treeNode ${props.modified ?"modified" :""}`}>
        <NodeChara char={charData.chara} id={charData.id}/>
        <div className="nodeRow">{nodes}</div>
    </div>
}

const NodeChara = (props: {char: string, id: number}) => {
    return <div>
        <Link to={`/chara/${props.id}`}>
            <span className="phun nodeChara">
                {props.char}
                <span className="serif nodeKanji">{props.char}</span>
            </span>
        </Link>
    </div>
}