import { Link } from 'react-router-dom';
import { Chara } from '../types/Dict';
import { CharaLink } from './top';
import { useParams } from 'react-router-dom';
import { NotFound } from './404';
import { weqoRank } from '../funcs';

interface Class {
    class: string;
    chara: Chara[];
}

const index = [
    {
        id: "strokes",
        name: "総画索引",
        label: (l: string) => l + "画",
        desc: (c: Chara) => c.chara,
        class: (c: Chara) => String(c.strokes),
        classSort: (a: Class, b: Class) => Number(a.class) - Number(b.class),
        charaSort: (a: Chara, b: Chara) => a.id - b.id
    },
    {
        id: "phun",
        name: "雰音索引",
        label: (l: string) => l ?l :"∅",
        desc: (c: Chara) => c.langs.pn.pron,
        class: (c: Chara) => weqoSplit(c.langs.pn.pron)[0],
        classSort: (a: Class, b: Class) => weqoClassCompare(a.class, b.class),
        charaSort: (a: Chara, b: Chara) => weqoCharaCompare(a.langs.pn.pron, b.langs.pn.pron)
    }
]

export const IndexPage = (props: any) => {
    const params = useParams()
    const key = params.key

    const indexFuncs = index.find(i => i.id == key)
    if (indexFuncs) { 
        const dictData: any[] = props.dict
        const classified = classify(dictData, indexFuncs.class)
        classified
            .sort(indexFuncs.classSort)
            .forEach(v => v.chara.sort(indexFuncs.charaSort))

        return (
            <div className='topWrapper'>
                <h1>{indexFuncs.name}</h1>
                <div className='indexList'>
                    {classified.map(s => <div key={s.class}>
                        <h2>{indexFuncs.label(s.class)}</h2>
                        <div className='charaList'>
                            {s.chara.map(v => 
                                <CharaLink id={v.id} chara={v.chara} desc={indexFuncs.desc(v)} key={v.id}/>
                            )}
                        </div>
                    </div>)}
                </div>
            </div>
        )
    } else {
        return <NotFound />
    }
}

const classify = (dict: Chara[], classFunc: (c: Chara) => string) => {
    const result: {class: string, chara: Chara[]}[] = []

    dict.forEach(c => {
        const strokes = classFunc(c)
        const match = result.find(v => v.class == strokes)
        if (match) {
            match.chara.push(c)
        } else {
            result.push({
                class: strokes,
                chara: [c]
            })
        }
    })
    return result
}

const weqoSplit = (c: string) => {
    const reg = c.match(/(.*?)([aiueo]+)(.*?)([123])/)

    if (!reg) {return []}

    return [reg[1], reg[2], reg[3], reg[4]]
}

const weqoClassCompare = (a: string, b: string) => {
    const aR = weqoRank[0].indexOf(a)
    const bR = weqoRank[0].indexOf(b)
        
    return aR - bR
}

const weqoCharaCompare = (a: string, b: string) => {
    const aSyl = weqoSplit(a)
    const bSyl = weqoSplit(b)

    if (!aSyl || !bSyl) {return 0}

    for (let i = 0; i < 4; i++) {
        const aR = weqoRank[i].indexOf(aSyl[i])
        const bR = weqoRank[i].indexOf(bSyl[i])
        
        if (aR > bR) return 1
        if (aR < bR) return -1
    }
    return 0
}